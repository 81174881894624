$background-color: rgba(0, 0, 0, .5);

.backdrop {
  background-color: $background-color;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;

}
