@import '../../assets/scss/_master';

.c-footer {
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: thin solid darkgray;
}

.c-footer button {
	margin-right: 1vw;
	height: 2.2vw;
	width: 7vw;
}
