//colors
$header-bg: #263646; 



$black: #000;
$white: #fff;
$blue: #0088b2;
$yellow: #fcff00;
$heading-green: #007972;
$btn-green: #02a850;
$btn-orange: #ff8a00;
$btn-blue: #0088b2;
$btn-grey: #2a2a2a;
$content-text-light: #707070;
$content-text-dark: #393939;
$muted-text-green: #008957;
$muted-text-light: #908787;
$muted-text-medium: #636363;
$muted-text-dark: #222222;
$label-yellow: #fdff4d;
$label-orange: #ffb400;
$label-dark: #232323;
$dropdown-hover-grey: #ebecf0;
$sidebar-list-grey: #545454; // SRP
$background-offwhite: #f1f1f1;
$background-grey: #acabac;
$background-darkgrey: #121921; // footer
$background-gradient-from: #810021;
$background-gradient-to: #34006a;
$error-red: #cc0000;
$sidebar-nav-link-active-blue: #00789d; // hover or active
$border-grey: #959595;
$border-offwhite: #ebebeb;
$shadow: #cccccc;

// breakpoints
$mob-min-width: 320px;
$mob-max-width: 576px;
$mob-width: 576px;
$tab-width: 992px;
$tab-min-width: 577px;
$tab-max-width: 992px;
$desk-min-width: 993px;
$desk-max-width: 1440px;
$min-width: 300px;
$max-width: 1600px;
$min-font: 32px;
$max-font: 36px;
$mob-mid-width: 375px;
$laptop-mid-width: 1024px;

// input height
$min-input-height: 40px;
$max-input-height: 50px;
$input-height: 40px;

// font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// font-sizes
$min-desk-header-font-size: 32px;
$max-desk-header-font-size: 36px;
$min-desk-sub-header-font-size: 26px;
$max-desk-sub-header-font-size: 30px;

$min-tab-header-font-size: 30px;
$max-tab-header-font-size: 34px;
$min-tab-sub-header-font-size: 25px;
$max-tab-sub-header-font-size: 28px;

$min-mob-header-font-size: 28px;
$max-mob-header-font-size: 32px;
$min-mob-sub-header-font-size: 25px;
$max-mob-sub-header-font-size: 27px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// for desktop
$desktop-header-font-size: calc(
  #{$min-desk-header-font-size} + #{strip-unit(
      $max-desk-header-font-size - $min-desk-header-font-size
    )} *
    (
      (100vw - #{$desk-min-width}) / #{strip-unit(
          $desk-max-width - $desk-min-width
        )}
    )
); //h1
$desktop-sub-header-font-size-big: calc(
  #{$min-desk-sub-header-font-size} + #{strip-unit(
      $max-desk-sub-header-font-size - $min-desk-sub-header-font-size
    )} *
    (
      (100vw - #{$desk-min-width}) / #{strip-unit(
          $desk-max-width - $desk-min-width
        )}
    )
); //h2
$desktop-sub-header-font-size-mid: 1.5VW; //h3
$desktop-sub-header-font-size-small: 20px; //h4
$desktop-btn-label-font-size: 18px; // button, label
$desktop-content-normal-font-size: 16px; // p, span, div etc.
$desktop-content-smaller-font-size: 14px; // p, span, div etc.

// for tab
$tab-header-font-size: calc(
  #{$min-tab-header-font-size} + #{strip-unit(
      $max-tab-header-font-size - $min-tab-header-font-size
    )} *
    (
      (100vw - #{$tab-min-width}) / #{strip-unit(
          $tab-max-width - $tab-min-width
        )}
    )
); //h1
$tab-sub-header-font-size-big: calc(
  #{$min-tab-sub-header-font-size} + #{strip-unit(
      $max-tab-sub-header-font-size - $min-tab-sub-header-font-size
    )} *
    (
      (100vw - #{$tab-min-width}) / #{strip-unit(
          $tab-max-width - $tab-min-width
        )}
    )
); //h2
$tab-sub-header-font-size-mid: 1.4vw; //h3
$tab-sub-header-font-size-small: 19px; //h4
$tab-btn-label-font-size: 17px; // button, label
$tab-content-normal-font-size: 16px; // p, span, div etc.
$tab-content-smaller-font-size: 14px; // p, span, div etc.

// for mobile
$mob-header-font-size: calc(
  #{$min-mob-header-font-size} + #{strip-unit(
      $max-mob-header-font-size - $min-mob-header-font-size
    )} *
    (
      (100vw - #{$mob-min-width}) / #{strip-unit(
          $mob-max-width - $mob-min-width
        )}
    )
); //h1
$mob-sub-header-font-size-big: calc(
  #{$min-mob-sub-header-font-size} + #{strip-unit(
      $max-mob-sub-header-font-size - $min-mob-sub-header-font-size
    )} *
    (
      (100vw - #{$mob-min-width}) / #{strip-unit(
          $mob-max-width - $mob-min-width
        )}
    )
); //h2
$mob-sub-header-font-size-mid: 1.25vw; //h3
$mob-sub-header-font-size-small: 18px; //h4
$mob-btn-label-font-size: 16px; // button, label
$mob-content-normal-font-size: 15px; // p, span, div etc.
$mob-content-smaller-font-size: 14px; // p, span, div etc.

$col-gutter-size: 15px;

// File end here

// Notes and previous code -

// According to design
// Main Heading -36px
// Sub-heading- 22px & 20px
// Buttons- 18px
// Paragraphs - 16px

// // Font-weight
// studentProfile {
//     desktop : {
//         header: medium;
//         editBtn: medium;
//         contentLabel: medium;
//         contentText: normal;
//         sidebar: {
//             name: normal;
//             email: light;
//             phone: light;
//             progBar: light;
//             navLinks: medium;
//         }
//         inputLabel: medium;
//         inputText: normal;
//         saveBtn: normal;
//     }
// }

// QnA {
//     desktop: {
//         banner: {
//             inputText: normal;
//             btn: medium;
//             tagBtn: normal;
//         }
//         questionText: medium;
//     }
// }

// // Font-Size
// studentProfile {
//     desktop : {
//         header: 36px;
//         editBtn: 18px;
//         contentLabel: 18px;
//         contentText: 18px;
//         sidebar: {
//             name: 24px;
//             email: 16px;
//             phone: 16px;
//             progBar: 18px;
//             navLinks: 18px;
//         }
//         inputLabel: 16px;
//         inputText: 16px;
//         inputBoxHeight: 40px;
//         saveBtn: {
//             textFont: 20px;
//             padding: 10px 40px;
//         }
//     }
// }

// QnA {
//     desktop: {
//         banner: {
//             inputBoxHeight: 50px;
//             inputText: 18px;
//             searchBtn: 24px;
//             askBtn: 24px;
//             tagBtn: 18px;
//             showAllTagBtn: 16px;
//         }
//         questionText: 24px;

//     }
// }

// contentText - 16px;
// anything smaller than that - 14px;

// buttonText - 18px;

// inputLabel - 16px;

// otherLabels - 18px;

// Header - 36px;

// Sub-header-big - 30px;

// Sub-header-medium - 24px;

// Sub-header-small - 20px;

// create a jira to have height property of input fields inside forms

// create class for label in forms (if needed, do the survey)

// If I get time, get style guide for forms as well

// colors:{
//   contenttext: {
//     white: #ffffff;
//     light: #616161; // homepage
//     // lightermedium: #585858; // college details
//     // medium: #585757; // student profile
//     dark: #393939; // mostly used
//     black: #000000; // news and events
//   }
//   mutedtext: {
//     white: #ffffff;
//     green: #008957; // homepage
//     // lightest3: #a6a6a6; // homepage
//     // lightest2: #908f8f; // homepage
//     light: #908787; // homepage
//     // lighter: #868585; // QnA
//     // light: #848484; // Reviews
//     // medium: #6a6969; // course details college name, SRP fees , homepage
//     medium: #636363; // student profile
//     // dark: #5f5e5e; // Reviews
//     dark: #222222; // news and events
//   }
//   labeltext: {
//     white: #ffffff;
//     yellow: #fdff4d; // hamburger menu
//     darkeryellow: #ffb400; // rating
//     blue: #0088b2; // course details, SRP
//     // light: #383838; // Reviews
//     dark: #232323; // college details facilities
//     // dark: #202020; // QnA
//     // darker: #1e1e1e; // SRP, homepage, Student profile, College details
//     black: #000000; // news and events, course details
//   }
//   heading: {
//     white: #ffffff;
//     green: #007972; // mostly used
//     blue: #0088b2; // course details, signup modal
//     // light: ;
//     // medium: ;
//     black: #000000; // homepage
//   }
//   link: {
//     white: #ffffff;
//     blue: #0088b2; // all over
//   }
//   dropdownhover: {
//     yellow: #fcff00; // header dropdown
//     grey: #ebecf0;
//   }
//   sidebarlist: {
//     white: #ffffff;
//     blue: #0088b2; // Student profile, college details
//     grey: #545454; // SRP
//   }
//   sidebarlistactiveorhover: {
//     blue: #00789d; // Student profile, college details
//   }
//   navlinkactiveorhover: {
//     sidebar: #fcff00;
//     header: #fcff00;
//   }
//   background: {
//     white: #ffffff;
//     // offwhite: #f3f3f3; // alternate with white on many pages
//     offwhite: #f1f1f1; // student profile
//     darkgrey: #121921; // footer
//     gradient: #810021 to #34006a; // header
//   }
//   table: {
//   }
//   button: {
//     text: {
//     }
//     background: {
//     }
//     border: {
//     }
//   }
// }

// buttons: {
//   btn-normal: {
//   }
//   btn-less-padding: {
//   }
//   btn-orange: {
//     background-color: #ff8a00;
//     border: 1px solid #ff8a00;
//     color: #ffffff;
//   }
//   btn-green: {
//     background-color: #02a850;
//     border: 1px solid #02a850;
//     color: #ffffff;
//   }
//   btn-green-white: {
//     background-color: transparent;
//     border: 1px solid #02a850;
//     color: #ffffff;
//   }
//   btn-blue: {
//     background-color: #0088b2;
//     border: 1px solid #0088b2;
//     color: #ffffff;
//   }
//   btn-blue-white: {
//     background-color: #ffffff;
//     border: 1px solid #0088b2;
//     color: #0088b2;
//   }
//   btn-trans-white: {
//     background-color: transparent;
//     border: 1px solid #ffffff;
//     color: #ffffff;
//   }
//   btn-white-orange: {
//     background-color: #ffffff;
//     border: 1px solid #ff8a00;
//     color: #ff8a00;
//   }
//   btn-rounded-sides: {
//     border-radius: 50px;
//   }
//   btn-rounded-corners: {
//     border-radius: 6px;
//   }
//   btn-sharp-corners: {
//     border-radius: 0;
//   }
//   view-all-load-more-btn: {
//     // ask Shweta
//   }
// }
