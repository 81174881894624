
.c-interview {
    :global {
        .ace_editor {
            font-size: 0.875rem !important;
            font-family:monospace
        }

        .ace_text-input {
            height: 1vw !important;
        }

        .editor {
            resize:vertical;
            overflow:auto;
        }

        .ace_content {
            background-color: black;
        }

        .ace_cursor {
           height: 1vw !important;
        };          
    }
}

.c-interview__code-editor {
    margin-top: 2.2vw;
    margin-left: 2.5vw;
    ::-webkit-scrollbar {
        width: 0.65vw;
        background:black;
        margin-right: 0.6vw;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: black;
        border-radius: 0.3vw;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        margin-right: 0.6vw;
        background: #888;
        border-radius: 0.4vw;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        margin-right: 0.6vw;
        background: #555;
      }
}

.c-interview__code-editor__candidate-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    overflow: hidden;
    >p {
        margin-left: 1vw;
    }
}

.candidate-status__left {
    display: inline-flex;
    justify-content: flex-start;

}

.candidate-status__right {
    display: inline-flex;
    justify-content: flex-end;
    font-size: 1rem;
    margin-right: 1vw;
}

.candidate-status__left-avatar {
    background-color: #00000040;
    color: #ffffff;
    font-weight: bolder;
    font-size: 1.2rem;
    margin-left:1vw;
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
    display: inline-grid;
    align-items: center;
}

.candidate-status__left-icon[active="true"] {
    height: 0.7vw;
    width: 0.7vw;
    margin-right: 1vw;
    margin-left: -0.5vw;
    margin-bottom: 0.7vw;
    background-color: #5FC262;
    border-radius: 50%;
    display: inline-block;
}

.candidate-status__left-icon[active="false"] {
    height: 0.7vw;
    width: 0.7vw;
    margin-right: 1vw;
    margin-left: -0.5vw;
    margin-bottom: 0.7vw;
    background-color: #986969;
    border-radius: 50%;
    display: inline-block;
}

.candidate-status__left-text {

}

.c-interview__code-editor__output-minimized {
    background: black;
    margin-top: 0.1vw;
    color: white;

    :global {
        .xterm {
            padding-top: 0.5vw;
        }
        .xterm-screen {
            height: 6vw !important;
        }
        .xterm-rows {
            font-size: 0.75rem !important;
            margin-left: 1vw !important;
            div {
                width: max-content !important;
                text-align: initial;
                line-height: 0.8vw !important;
                height: 0.8vw !important;
            }
        }
        .xterm-rows span {
            width: auto !important;
            display: initial !important;
        }
    }
}

.c-interview__code-editor__output-maximized {
    background: black;
    margin-top: 0.1vw;
    color: white;
    :global {
        .xterm {
            padding-top: 0.5vw;
        }
        .xterm-screen {
            height: 30vw !important;
        }
        .xterm-rows {
            font-size: 0.75rem !important;
            margin-left: 1vw !important;
            div {
                width: max-content !important;
                text-align: initial;
                line-height: 0.85vw !important;
                height: 0.85vw !important;
            }
        }
        .xterm-rows span {
            width: auto !important;
            display: initial !important;
        }
        
    }
}

.c-interview__code-editor__output-result {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    align-items: flex-start;
    
}

.c-interview__code-editor__output-spinner {
    text-align: center;
    border-radius: 0.25vw;
    margin-bottom: 1.25vw;
    padding: 1.875vw 3.125vw;
    margin: 1.25vw 0vw;
}

.c-interview__chat {
    // height: 36.500vh;
    margin-top: 1vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    padding-top: 1.2vw;
}

.c-interview__meeting {
    // height: 36.500vw;
    // margin-left: 2.5vw;
    // margin-right: 2.5vw;
}

.c-interview__selector {
    margin-top: 1vw;
} 

.c-interview__output-icon-close {
    font-size: 0.85rem;
}

.c-interview__output-icon-minmax {
    font-size: 0.85rem;
    margin-top: 0.1vw;
    margin-right: 0.6vw;
	padding-top: 0.5vw;
}

.screen-share-status{
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.screen-share-status div{
    background-color: #f0f1f2;
    padding: 0.6rem;
    border-radius: 0.2rem;
    border: 1px solid  rgb(47, 185, 232);
}
