.form {
	width: 72%;
	margin-left: 3.7vw;
	:global {
		.ant-form-item {
		 	margin-bottom: 0.75vw;
		}
		.ant-checkbox-wrapper {
			margin-right: 3.5vw !important;
		}
		.ant-form-explain {
			display: flex;
		}
    }
}
.form >div:first-child {
	margin-bottom: 0.8vw;
}

.form__button {
	width: 100%;
	margin-top: 0.9vw;
	margin-bottom: 0.65vw;
}

.form__forgot {
	float: right;
}