@import '../../../assets/scss/master';

.form {
	text-align: initial;
	:global {
		.ant-form {
			font-size: 0.875rem;
		}

		.ant-form label {
			font-size: 1rem !important;
		}

		.ant-from-item {
			margin-bottom: 0vw;
		}
		.ant-form-item-label {
			line-height: 3vw;
		}
		.ant-form-item-label > label.ant-form-item-required:before {
			content: unset;
		}

		.ant-form-explain {
			display: none;
		}
		
		.ant-form-item-label > label.ant-form-item-required:after {
			content: unset;
		}

	}
}

.form-rating {
	margin-bottom: 0vw;
	:global {
		.ant-radio-wrapper {
			font-size: 0.875rem;
			margin-right: 3vw;
			line-height: 1.5vw;
		}

		.ant-form-item-required {
			font-size: 1rem !important;
		}

		.ant-form-item-control {
			line-height: 2.500vw;
		}

		.ant-radio-group {
			font-size: 1rem;
		}

		.ant-radio {
			font-size: 1rem;
		}

		.ant-radio-input {
			width: 0.850vw;
			height: 0.850vw;
		}

		.ant-radio-inner {
			width: 1vw;
			height: 1vw;
			border-width: 0.063vw;
		}

		span.ant-radio + * {
			padding-right: 0.500vw;
			padding-left: 0.500vw;
		}

		.ant-radio-inner::after {
			top: 0.188vw;
			left: 0.188vw;
			width: 0.5vw;
			height: 0.5vw;
			border-radius: 0.5vw;
		}
	}
}

.form-feedback {
	font-size: 0.875rem;
	margin-bottom: 0vw;

	:global {
		.ant-form-item-required {
			font-size: 1rem !important;
		}
		.ant-form-item-control {
			line-height: 2.500vw;
		}
	}
}

.form-button {
	margin-bottom: 0vw;
	font-size: 1rem;
	line-height: 1vw;

	:global {
		.ant-form-item-control {
			line-height: 2.500vw;
		}

		.ant-btn {
			line-height: 1.5vw;
			border-width: 0.063vw;
			height: 2vw;
			padding: 0 1vw;
			font-size: 1rem;
			border-radius: 0.250vw;
			width: 9.653vw;
		}
	}
}

.form-feedback__textarea {
	width: 85%;
	height: 19.8vw;
	line-height: 1.5vw;
	resize: none;
	font-size: 0.875rem ;
	margin: 0;
	overflow-y: auto;
	padding: 2%;
	border-width: 0.063vw;
}