.c-problem-selector {
	padding: 10px 0px;
}

.c-problem-selector__points {
	font-size: 1.2rem;
}

.c-problem-selector__plist {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 20px;
}

.c-problem-selector__list-item {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.c-problem-selector__marker-div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.c-problem-selector__marker {
	width: 10px;
	height:40px;
}
.c-problem-selector__marker[ck_selected="true"] {
  border-top: 1.9vw solid transparent;
  border-bottom: 1.9vw solid transparent;
  border-left: 1vw solid #24B9E8;
}
.c-problem-selector__marker-box {
	width: 0.3vw;
	height: 3.8vw;
}
.c-problem-selector__marker-box[ck_selected="true"] {
	background-color: #24B9E8;
}
.c-problem-selector__balancer {
	width: 7px;
	height: 40px;
}

.c-problem-selector__icon {
	width:2.7vw;
	height:2.7vw;
	border-radius:2vw;
	border: 1px solid black;
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	cursor: pointer;
	border-color: #66A391;
}

.c-problem-selector__icon[status="ALL_PASS"] {
	background-color: #66A391;
	border-color: #66A391;
	color: #fff;
}

.c-problem-selector__icon[status="SOME_PASS"] {
	background-color: #CFC13E;
	border-color: #CFC13E;
	color: #fff;
}

.c-problem-selector__icon[status="ALL_FAIL"] {
	background-color: #FC8263;
	border-color: #FC8263;
	color: #fff;
}