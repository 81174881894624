@import "../../assets/scss/master";

.c-interview-invite{
    background-color:#F0F3F7;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    overflow-x: hidden;
}

.c-interview-invite__heading {
    .c-invite__logo {
        margin: 0 auto;
        padding-top: 8vw;
        width: 16.25vw;
        margin-right: 41.75vw;
    }
    .c-invite__logo img {
        width: 17.25vw;
    }
    .c-interview-invite__title {
        margin-top: 1vw;
        font: normal normal normal 1.75vw/2.75vw sans-serif;
        letter-spacing: 0px;
        color: #141414;
        text-transform: uppercase;
        opacity: 0.62;
        h5 {
            padding-left: 0.35vw;
            font-size: 1.75vw;
        }
    }
}

.c-interview-invite__name {
    margin-top: 3vw;
    p {
        text-align: center;
        font: normal normal bold 1.25vw/2.5vw sans-serif;
        letter-spacing: 0px;
        color: #3B3B3B;
        opacity: 1;
        width: 100%;
        font-size: 1.12vw;
        margin-left: 0.2vw;
    }
}

.c-interview-invite__footer {
	text-align: center;
    margin-top: 10vw;
	padding-bottom: 0.625vw;
	p {
		padding-bottom: 0.625vw;
		margin: 0px;
        font-size: 0.75rem !important;
        font: normal normal normal 0.75vw/1.25vw sans-serif !important;
        letter-spacing: 0px;
        color: #00000073;
        opacity: 1;
	}
}

.c-interview-invite__timer{
    display: flex;
    margin-top: 1.5vw;
    margin-left: 34.8%;
    height: 15%;
    margin-bottom: 7.5vw;
    
    .c-interview-invite__details__days{
        width: 7.14vw;
        height: 6.28vw;
        background: #3D5BBE 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        flex-direction: column;
    }
    .c-interview-invite__details__hours {
        width: 7.14vw;
        height: 6.28vw;
        background: #5C82FF 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        margin-left: 0.5vw;
        flex-direction: column;
    }
    .c-interview-invite__details__minutes {
        width: 7.14vw;
        height: 6.28vw;
        background: #1CB9FF 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-left: 0.5vw;
        display: flex;
        flex-direction: column;
    }

    .c-interview-invite__details__seconds {
        width: 7.14vw;
        height: 6.28vw;
        background: #53E5FF 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-left: 0.5vw;
        display: flex;
        flex-direction: column;
    }
    .c-interview-invite__details-tag {
        text-align: center;
        font: normal normal normal 0.75vw/1vw sans-serif;
        letter-spacing: 0.02vw;
        color: #5C82FF;
        margin-top: 2vw;
        text-transform: uppercase;
    }
    p {
        width: 4vw;
        height: 5vw;
        font: normal normal normal 3.25vw/4.33vw sans-serif;
        letter-spacing: -0.065vw;
        color: #FFFFFF;
        margin-top: 1vw;
        margin-left: 1.5vw;
    }
}

.c-interview-invite__login {
    margin-left: 1vw;
    height: 1vw;
    button {
        width: 11vw !important;
        height: 2.2vw !important;
        font-size: 0.875rem !important;

        div {
            padding: 0vw !important;
            padding-left: 0.625vw !important;
            font-size: 0.875rem;
            border-radius: 0.125vw;
        }
    }
}

.login {
  // margin-top: 10vw;
}

.login__content {
	height: 100%;
	display: flex;
	align-items: center;
}

.login__form {
    width: 26vw;
    margin-left: 37%;
    height: 69.5vh;
    padding-top: 8vw;
    margin-bottom: 3.3%;
	:global {
		.ant-form-item-control{
			font-size: 0.875rem !important;
			line-height: 2.5vw;
		}
		.ant-btn-primary {
			height: 2vw;
			padding: 0vw 1vw;
			border-radius: 0.25vw;
		}
		.ant-btn-primary span{
			font-size: 0.875rem;
		}
		.ant-form label{
			font-size: 0.875rem;
		}
		.ant-checkbox-inner {
			height: 1vw;
			width: 1vw;
		}
		.ant-checkbox-inner::after {
			width: 0.35625vw;
			height: 0.571875vw;
		 }
		.ant-input {
			height: 2vw;
			padding: 0.25vw 0.75vw;
			font-size: 0.875rem;
		}
		.ant-input-affix-wrapper {
			font-size: 0.875rem;
		}
		.ant-input-affix-wrapper .ant-input:not(:first-child) {
			padding-left: 2vw;
		}
	}
}

.login__logo {
	padding: 1vw;
    padding-bottom: 1.25vw;
    width: 65%;
    margin-left: 4.5vw;
    h5 {
        padding-left: 0.35vw;
        font-size: 1.75vw; 
        margin-top: 1vw;
        font: normal normal normal 1.5vw/1.75vw sans-serif;
        letter-spacing: 0px;
        color: #141414;
        text-transform: uppercase;
        opacity: 0.62;
    }
}

.login__candidateinfo {
    padding: 0.5vw 5vw;
}

.candidateinfo__user {
    display: flex;
    margin-bottom: 1vw;

}

.candidateinfo__role {
    display: flex;
}

.login__form__content {
    background-color: white;
    padding-top: 0.6vw;
    padding-bottom: 0.6vw;
}

.candidate__sign-in {
    margin-top: 1vw;
    color: #3B3B3B;
    opacity: 1;
    padding-right: 14.7vw;
    margin-top: 2vw;
    font: normal normal bold 1.25vw/2.5vw sans-serif;
}
