@import '../../../assets/scss/_master';

.custom_io {
	border: 1px solid #7070702B;
	margin-bottom: 20px;


h3 {
	background: #FBFCFC 0% 0% no-repeat padding-box !important;
    padding:5px !important;
    font-size: 1.1rem;
  }
}

.custom_io_body {
	display: block;
	border-width: 1px 0px;
	border-color: #7070702B;
	border-style: solid;
	padding: 5px;
	width: 100%;
	resize: none;
}

.c-custom-input__output--errors {
	color: red;
}