@import '../../assets/scss/_master';

.EditorHeader {
	height: 10%;
	padding:0.5vw;
	display:flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid darkgray;
}

.headerRight {
	display: flex;
	align-items: center;
	font-size: 1.15rem;
	flex: 1 0 auto;
	justify-content: space-evenly;
	:global{
		.ant-switch {
			padding: 0 0.2vw;
			width: 2.750vw;
			height: 1.375vw;
		}
		.ant-switch-inner {
			font-size: 1.1rem;
		}
		.ant-switch::after {
			width: 1vw;
			height: 1vw;
			top: 0.13vw;
			border-radius: 1vw;
		}
	}
}


.headerLeft {
	flex: 0 0 auto;
	padding: 0 0.8125vw;
	font: normal normal normal 1vw/1.22vw Roboto;
    letter-spacing: 0px;
    color: #5A5151;
	opacity: 1;
	font-size: 1rem;
}

	:global{
        .ant-select {
			width: 12vw !important;
		}
        .ant-select-selection {
            height: 1.5vw;
        }
		.ant-select-selection__rendered {
			margin-right: 0.75vw !important;
			margin-left: 0.75vw !important;
			line-height: 1.5vw !important;
		}
    }
    .form__button { 
		margin-right: 1vw;
        height: 2.3vw !important;
        padding: 0vw 2vw !important;
        font-size: 1rem !important;
		border-radius: 0.25vw !important;
		background: #8A969B 0% 0% no-repeat padding-box;
		border: 1px solid #7D8D92;
		border-radius: 5px;
		opacity: 1;
		color: white;
    }


.toggleExpandButton {
	margin-left: 1vw;
	font-size: 125%;
	cursor:pointer;
}


.languageSelector {
	width: 12.5vw;
		
}