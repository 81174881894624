@import '../../assets/scss/_master';

.results-tab__scrollbar {
	padding-right: 5px;
	padding-top: 4px;
	padding-bottom: 4px;
	height: 100%;
}

.results-tab {
	text-align:left;
	padding: 10px 15px;
	height: 100%;
	overflow-y: scroll;

	:global {
		.ant-table {
			font-size: 100%;
		}
	}

	&::-webkit-scrollbar {
		width: 0.7vw;
	}
	&::-webkit-scrollbar-track {
		background-clip: content-box;
	}
	&::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
		border-radius: 1vw;
	}
}

.results-tab h3 {
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.95);
	padding: 11px 0px;
}

.results-tab p {
	margin-bottom: 10px;
	font-size: 1rem;
}

.verdict-table {
	background:none;
	width:inherit;
}

.verdict-table thead > tr > th {
	padding: 5px 0px;
	padding-right: 10px;
	text-align: center;
	border-style: none;
	background: none;
}

.verdict-table tbody > tr > td {
	padding: 5px 0px;
	padding-right: 10px;
	border-style:none;
	text-align: center;
}

.grn {
	color:green;
}

.dangr {
	color:red;
}

.orng {
	color:orange;
}

.dfault {
	color: gray;
}

