.roster {
    float: right;
    border: 1px solid #B6B6B6;
    height: auto !important;
    width: 100% !important;
    min-height: calc(2.64vw + 60vh);
    text-align: left;
}

.participants{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: 0 0.5rem;
    background-color: #ffffff;
    border-radius: 0.25rem;
}

.participants, .participants_title{
    font-size: 0.875rem;
    color: #3f4149;
    padding: 0.625rem 1rem;
}

.participants_title{
    border-top: 0.0625rem solid #d4d5d8;
    margin-top: 0.625rem;
}