@import '../../assets/scss/_master';

.desc-style {
	font-weight:bold;
	margin-top:20px;
	text-align:left;
	padding:1px;
	font-size: 1.1rem;
}

.titleStyle {
	font-weight: bold;
	text-align: left;
	font-size: 1.1rem;
}

.contentstyle {
	text-align:left;
	padding:1px;
	font-size: 1rem;
}

.c-problem-description__scrollbar {
	padding-right: 5px;
	padding-top: 4px;
	padding-bottom: 4px;
	height: 100%;
}

.c-problem-description {
	padding: 13px 15px;
	height: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 0.7vw;
	}
	&::-webkit-scrollbar-track {
		background-clip: content-box;
	}
	&::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
		border-radius: 1vw;
	}
}