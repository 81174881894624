.video-layout {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    width: 100%;
    height: 100% !important;
    border: 0.0625rem solid #d4d5d8;
}

.video-layout:has(div:nth-child(2)) {
    grid-template-rows: auto auto;
}

.video-layout:has(div:nth-child(4)) {
    grid-template-rows: auto auto auto;
}

.video-layout video {
    border-right: 0.0625rem solid #d4d5d8;
    border-bottom: 0.0225rem solid #d4d5d8;
}

.video-layout::-webkit-scrollbar {
    display: none;
}

.video-layout {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}