.c-editor {
	height: 100%;
	border:1px solid #828282;
	flex-direction: column;
}

.c-editor__ace {
	height: 80%;
}

:global {
	.view-overlays > div {
		background-color: rgba(128, 128, 128, 0.2);
	}
	.view-overlays > div:has(div.editableArea--multi-line) {
		background: none;
	}
	.ace_content {
		background-color: rgba(0,0,0,0.00);
	}
}

