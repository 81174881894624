@import '../../assets/scss/_master';

.EditorHeader {
	height: 10%;
	padding:8px;
	display:flex;
	align-items: center;
	flex-wrap: wrap;
	border-bottom: 1px solid darkgray;
}

.headerLeft {
	flex: 0 0 auto;
	padding: 0 0.625vw;
	font-size: 1.15rem;
}

.headerRight {
	display: flex;
	align-items: center;
	font-size: 1.15rem;
	flex: 1 0 auto;
	:global{
		.ant-switch {
			padding: 0 0.2vw;
			width: 5.7vw;
			height: 1.8vw;
		}
		.ant-switch-inner {
			font-size: 1.1rem;
		}
		.ant-switch::after {
			width: 1.3vw;
			height: 1.3vw;
			top: 0.19vw;
			border-radius: 1vw;
		}
	}
}

.toggleExpandButton {
	font-size: 125%;
	cursor:pointer;
	padding-right: 1vw;
	padding-left: 1vw;
}


.languageSelector {
	width: 200px;
		
}