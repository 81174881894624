.c-option-selector {
	margin-top: -0.5vw;
}

.c-option-selector__list {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.c-option-selector__list-item {
	margin-bottom: 1.250vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.c-option-selector__marker-div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.c-option-selector__marker {
	width: 0.625vw;
	height:2.5vw;
}
.c-option-selector__marker[ck_selected="true"] {
	border-top: 1.9vw solid transparent;
	border-bottom: 1.9vw solid transparent;
	border-right: 1vw solid #24B9E8;
}
.c-option-selector__marker-box {
	width: 0.3vw;
	height: 3.8vw;
}
.c-option-selector__marker-box[ck_selected="true"] {
	background-color: #24B9E8;
}
.c-option-selector__balancer {
	height: 2.5vw;
	width: 3vw;
}

.c-option-selector__icon {
	width:2.9vw;
	height:2.7vw;
	font-size: 1.625vw;
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	cursor: pointer;
}

.notification__icon {
	height: 0.750vw;
	width: 0.750vw;
	background-color: green;
	border-radius: 50%;
	display: inline-block;
	margin-bottom: 1.3vw;
}