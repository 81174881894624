.c-layout {
	height: 100vh;

	.c-layout__header {
		height: 12%;
	}

	.c-layout__content {
		height: 82%;
		overflow:auto;
	}

	.c-layout__content[full="true"] {
		height: 100%;
	}

	.c-layout__footer {
		height: 6%;
	}
}