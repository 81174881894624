.c-empty-notes{
    border: 0.0625vw solid #707070;
    height: 100%;
}

.c-empty-notes__header {
    height: 3vw;
    border-bottom: 0.0625vw solid #707070;
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.c-empty-notes__header-text {
    margin-left: 1vw;
    font-size: 1rem;
    font-weight: 500;
}

.c-empty-notes__body {
    padding-top: 13vw;
    :global {

        .ant-empty {
            margin: 0 0.5vw;
            font-size: 1rem;
            line-height: 1.375vw;
        }

        .ant-empty-image {
            height: 6.250vw;
            margin-bottom: 0.5vw;            
        }

        .ant-empty-description {
            color: #707070;
        }
    }
}