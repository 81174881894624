@import '../../assets/scss/_master';

.c-msg-box {
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items:center;
	height: 68vh;
	margin-top: 11vh;
}

.c-msg-box h2 {
	color: $content-text-light;
	padding: 0.5vw 0px;
}

.c-msg-box {
	line-height:1.5;
}

.c-msg-box__top-image {
	img {
		height: 12.25vw;
	}
}

.c-msg-box__bottom-image {
	display: flex;
	justify-content: space-between;
	align-self: stretch;
	align-items: center;
}

.c-msg-box__bottom-image-left {
	margin-left: 3.125vw;
}

.c-msg-box__bottom-image-right {
	margin-right:2.5vw;
}

.c-msg-box__instruction{
    margin-top: 0.75vw;
    text-align: center;
    font: normal normal normal 1.25vw/1.5vw Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

