@import "../assets/scss/master";

.l-wrapper {
	background-color: $header-bg;
	min-height: 100vh;
	padding: 2.8vw 0px;
}

.c-container {
	background-color: $white;
	max-width: 52.5vw;
	min-height: 27.1vw;
	margin-left:auto;
	margin-right:auto;
}