@import '../../assets/scss/_master';

.l-problem-container {
	padding: 20px 0px;
	height: 100%;
	.l-problem-container__row {
		height: 100%;
	}
}

.c-problem-def-results {
	height: 100%;
	border: 1px solid #828282;
	:global {
		div.ant-tabs-nav {
			display: block;
		}
		div.ant-tabs-nav.ant-tabs-nav-animated > div {
			display: flex;
		}
		.ant-tabs {
			height: 100%;
			font-size: 100%;
		}
		.ant-tabs-bar {
			margin-bottom:0;
			border-bottom: 1px solid #828282;
		}
		.ant-tabs-content {
			height: 90%;
			// overflow-y: auto; // causing issue, implement inside
		}
		.ant-tabs-tab{
			font-size: 1.2rem;
			flex-grow: 1;
			width: 12.7vw;
		}
		.ant-tabs-tab-active {
			background-color: lightgray;
			border-left: 1px solid #828282;
			border-right: 1px solid #828282;
			color: black;
			font-weight: normal;
		}
		.ant-tabs-ink-bar {
			height: 1px;
			background-color: #828282;
		}
		.ant-tabs-nav.ant-tabs-nav-animated > div > div:first-child  {
			border-left: 0px;
		}
		.ant-tabs-nav.ant-tabs-nav-animated > div > div:last-child  {
			border-right: 0px;
		}
	}
}