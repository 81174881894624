table tr > th {
	width:25%;
}

table tr > th.verdict-index {
	text-align:left;
	padding-left: 0px;
	width: 25%;
}
table tr > td.verdict-index {
	text-align:left;
	padding-left: 35px;
}

table tr > th.verdict-difficulty {
	text-align:left;
	padding-left: 5px;
}
table tr > td.verdict-difficulty {
	text-align:left;
	padding-left: 5px;
}


table tr > th.verdict-points {
	text-align:center;
	padding-left: 20px;
}
table tr > td.verdict-points {
	text-align:center;
	padding-left: 20px;
}


table tr > th.verdict-logo {
	text-align:left;
	padding-left: 25px;
}
table tr > td.verdict-logo {
	text-align:left;
	padding-left: 25px;
}
table tr > td.verdict-logo > .fa {
	width:15px;
}