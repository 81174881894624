@import '../../../assets/scss/master';

.c-chat {
	height: 100%;	

	::-webkit-scrollbar {
        width: 0.65vw;
	}
	
    ::-webkit-scrollbar-track {
        background: #ffffff;
    }
      
    ::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
        border-radius: 0.4vw;
    }
}

.c-chat__header {
	background: 0% 0% no-repeat padding-box padding-box rgb(248, 248, 248);
	border: 0.125vw solid #7D8D92;
	height: 3.5vw;
	text-align: left;
	display: flex;
	padding: 1vw 1vw;
}

.c-chat__body {
	border: 0.063vw solid #7D8D92;
	height: 60vh;
	border-top: 0vw;
	overflow-y: auto;
}

.c-chat__footer {
	border: 0.063vw solid #7D8D92;
	height: 3.17vw;
	border-top: 0vw;
	:global {
		.ant-input {
			height: 3.17vw;
			width: 92%;
			float: left;
			border: none;
			border-bottom: 0.063vw solid #7D8D92;
			border-radius: 0vw;
			font-size: 1vw;
		}

		.ant-input:focus {
			box-shadow: none;
		}

		.anticon {
			display: flex;
			padding-top: 0.7vw;
			font-size: 1.563vw;
			color: #268BF0;
		}
	}
}

.c-chat__footer > input:focus {
	outline: none;
} 

.chat__self {
	:global {
		.ant-comment-inner {
			padding-top: 0px !important;
		}
		.ant-comment-content-author {
			justify-content: flex-end;
		}
	}
}

.chat-self__message {
	border-radius: 0.8vw;
	text-align: left;
	background-color: #8A969B;
	width: fit-content;
	max-width: 20vw;
	float: right;
	padding: 0.625vw 0.875vw;
	margin-right: 1vw;
	color: white;
	font-size: 0.875rem;
}

.chat__other {
	margin-left: 1vw;
	:global {
		.ant-comment-content {
			font-size: 0.875vw;
		}
		.ant-comment-inner {
			padding-bottom: 0.5vw !important;
		}
		.ant-comment-content-author {
			justify-content: flex-start;
		}
		.ant-avatar {
			height: 2vw;
			width: 2vw;
			font-size: 1vw;
			line-height: 2vw;
		}
		.ant-comment-content-author > span {
			height: 1.125vw;
			padding-right: 0.5vw;
			font-size: 0.750vw;
			line-height: 1.125vw;
		}
	}
}

.chat-other__message {
	border-radius: 0.8vw;
	text-align: left;
	background-color: #F3F3F3;
	width: fit-content;
	max-width: 20vw;
	float: left;
	padding: 0.625vw 0.875vw;
	font-size: 0.875rem;

	:global {
		.ant-comment-inner {
			padding-bottom: 0px !important;
		}
	}
}

.chat-userstatus {
	text-align: center;
	padding: 0.750vw 1vw;
}

.chat-userstatus > p {
	color:  #7D8D92;
	font-size: 0.8rem;
}


.chat-status__icon {
	height: 0.75vw;
	width: 0.75vw;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
	margin-top: 0.05vw;
    margin-right: 0.5vw;
}

.chat-status__icon[connected="true"] {
	height: 0.75vw;
	width: 0.75vw;
	background-color: green;
	border-radius: 50%;
	display: inline-block;
	margin-top: 0.05vw;
    margin-right: 0.5vw;
}