@import "../../assets/scss/master";

.l-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: $header-bg;
	color: #fff;

	width: 100%;
	height: 100%;
	padding: 0vw 3vw;

	:global {
		.ant-progress-inner {
			border-radius: 2px;
			background-color: #263646;
			border: 1.5vw solid #fff;
		}
		
		.ant-progress-bg {
			background-color: #fff;
		}

		.ant-btn-lg {
			height: 2.9vw;
			width: 8.8vw;
		}
	}
}

.c-logo {
	display: flex;
	justify-content: flex-start; 
}

.c-logo__image {
	height: 2.9vw;
}

.c-problem-counter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.c-problem-counter__progress_bar {
	:global {
		div.ant-progress-bg {
			height: 1.1vw !important;
		}

		.ant-progress-inner {
			width: 9.9vw;
			border-width: 0.15vw;
		}
	}
}

.progress-counter {
	font-weight: 500;
	font-size: 1.2rem;
	margin-left: 1rem;
}

.c-timer {
	font-size: 1.2rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-weight: 500;
}

.c-timer--green {
	color:#fff;
}

.c-timer--red {
	color:#FF8888;
}

.c-timer--yellow {
	color:#FFF065;
}

.c-timer i {
	font-size: 2rem;
}

@media (max-width: $tab-width) {
	.l-header {
		height: 56px;
		padding: 15px 22px;
	}

	.c-logo {
		display: none;
	}
}

@media (max-width: $mob-width) {
	.l-header {
		height: 56px;
		padding: 0;
	}
}