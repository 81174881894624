$table-content-font-size: 1.15rem;
$table-header-font-size: 1.15rem;

.c-problem-list {
	padding: 0px;
	:global {
		thead th.pt_title, thead th.pt_points, thead th.pt_type, thead th.pt_status {
			font-size: $table-header-font-size;
			width:15%;
		}
		thead th.pt_title {
			width:35%;
			padding-left: 40px;
		}
		thead th.pt_type {
			width: 17%;
		}
		tbody td.pt_action {
			text-align:right;
		}
		tbody td.pt_title, tbody td.pt_points, tbody td.pt_type, tbody td.pt_status {
			font-size: $table-content-font-size;
		}

		tr th, tr td {
			border-bottom: 1px solid #707070;
			vertical-align: middle;
		}

		tbody td.pt_title {
			padding-left: 40px;
		}
		tbody td.pt_action {
			padding-right: 40px;
		}

		tbody td.pt_action span.reattempt {
			color: #0077CC;
			cursor: pointer;
			font-size: 1.1rem;
		}
		tbody td.pt_status .unattempted {
			width: 2rem;
			height: 2rem;
			border-radius: 2rem;
			border: 0.18rem solid #707070;
			display: inline-block;
		}

		tbody td.pt_status {
			line-height: 2rem;
			height: 2rem;
			vertical-align: middle;
		}

		tbody td.pt_status> div {
			display: flex;
			text-align: center;
		}

		tbody td.pt_status> div span:nth-child(2) {
			padding-left: 1vw;
		}
		
		.ant-btn-lg {
			height: 2.9rem;
			width: 9.3rem;
			font-size: 1.05rem;
			font-weight: 500;
		}
	}
}