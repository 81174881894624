:global {
  .flash-message-list__container {
    z-index: 9999;
    position: fixed;
    transform: translate(-50%);
    left: 50%;
    top: 10%;
    * {
      margin: 0.75em 0;
    }
  }
}
