@import '../../assets/scss/_master';

.container{
	padding-top:18px;
	margin-top: 37px;
}

.container__image {
	width: 500px;
}

.container__message{
	font-size:$desktop-sub-header-font-size-mid;
	padding-top: 13px;
	padding-bottom:10px;
	display: flex;
	justify-content: space-around;
	:global {
		.ant-alert-with-description .ant-alert-message {
			text-align: left;
		}
	}
}

.container__message--blue{
	color:#5E728E;
}

@media (max-width: $mob-width) {
	.container__image{
		width:350px;
	}
}