@import '../../../assets/scss/_master';

.c-notes-list {
    background-color: #9E9E9E;
    min-height: 98%;
}

.notes-list {
    :global {
        .ant-list-item {
            display: block;
            text-align: left;
            border-bottom: none;
        }
    }
}

.notes-list__item {
    display: block;
    text-align: left;
    padding: 1vw 2.5vw;
    padding-top: 0vw;
}

.notes-list__itemdata-edit {
    border-radius: 0.625vw 0.625vw 0 0;
    background-color:#DBEAEF;
    padding: 1vw;
    margin-bottom: 0vw;
    display: block;
    min-width: 100%;
    font-size: 1rem;
    resize: none;
    border: none;
    font: normal normal normal 1vw/1.188vw Roboto;

}

.notes-list__itemdata-view {
    border-radius: 0.625vw 0.625vw 0 0;
    background-color:#DBEAEF;
    padding: 1vw;
    margin-bottom: 0vw;
    font-size: 1rem;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;  
    font: normal normal normal 1vw/1.188vw Roboto;

}

.notes-list__footer {
    display: flex;
    padding-top: 2%;
    font-size: 1rem;
    height: 3vw; 
    background-color: #263646;
    border-radius: 0 0 0.625vw 0.625vw;
    padding: 0.8vw 1vw 1vw;
}

.footer-time {
    flex: 1 0 auto;
    color: #ffffff;
}
.footer-options__view {
    flex: 0 0 auto;
    color: #ffffff;
}

.footer-options__modify {
    flex: 0 0 auto;
    color: #ffffff;
}

.footer-options__edit {
    margin-right: 0.8vw;
    cursor: pointer;
    color: #ffffff;
    > i {
        padding-right: 0.4vw;
    }
}

.footer-options__delete {
    cursor: pointer;
    padding-left: 0.6vw;
    color: #ffffff;
    > i {
        padding-right: 0.4vw;
    }
}

.footer-options__cancel {
    margin-right: 0.8vw;
    cursor: pointer;
    color: #ffffff;
    > i {
        padding-right: 0.4vw;
    }
}

.footer-options__save {
    cursor: pointer;
    color: #ffffff;
    > i {
        padding-right: 0.4vw;
    }

}